<template>
  <div>
    <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
      <el-form-item label="每分钟语音通话分成">
        <el-input v-model="dataForm.telephoneCutPerMin" placeholder="telephoneCutPerMin" :disabled="visible" clearable></el-input>
        <el-button @click="edit()" type="danger">编辑</el-button>
      </el-form-item>
      <el-form-item label="每分钟视频通话分成">
        <el-input v-model="dataForm.videoCallCutPerMin" placeholder="videoCallCutPerMin" :disabled="visible" clearable></el-input>
      </el-form-item>
      <el-form-item label="每条文字分成">
        <el-input v-model="dataForm.textChatCost" placeholder="文字消息分成" :disabled="visible" clearable></el-input>
      </el-form-item>
      <el-form-item label="每朵玫瑰花分成">
        <el-input v-model="dataForm.roseGiftCut" placeholder="roseGiftCut" :disabled="visible"  clearable></el-input>
        <el-button @click="setCut()" type="primary" :disabled="buttonVisible"  >保存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>

const sourceDataForm = {
  telephoneCutPerMin:'',
  videoCallCutPerMin:'',
  roseGiftCut:'',
  textChatCost:''
}
export default {
  data() {
    return {
      dataForm: JSON.parse(JSON.stringify(sourceDataForm)),
      dataList: [],
      visible: true,
      buttonVisible:true
    }
  },
  activated() {
    this.getDataList()
  },
  methods: {
    refresh() {
      this.getDataList()
    },
    // 获取数据列表
    getDataList() {
      this.dataListLoading = true
      this.$http({
        url: this.$http.adornUrl('/admin/anchor/getCut'),
        method: 'post',
        data: this.$http.adornData({
        })
      }).then(({ data }) => {
        if (data && data.code === 0) {
           this.dataForm = data.result
        } else {
          this.$message.error(data.msg)
        }
        this.dataListLoading = false
      })
    },
    setCut() {
      this.dataListLoading = true
      this.$http({
        url: this.$http.adornUrl('/admin/anchor/setCut'),
        method: 'post',
        data: this.$http.adornData({
          ...this.dataForm
        })
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.getDataList()
          this.$message.info("更新成功")
        } else {
          this.$message.error(data.msg)
        }
        this.dataListLoading = false
      })
    },
    edit() {
      this.visible = false
      this.buttonVisible = false
    },
    // 每页数
  },
}
</script>

<style scoped lang="scss">
.el-table {
  .el-button+.el-button {
    margin-left: 0;
  }
}
</style>
